import React, { Fragment } from "react"
import PropTypes from "prop-types"

const List = props => {
  const { className, items, type } = props

  const renderItemTitleList = title => {
    if (!title) return

    return <span className="List-item-title">{title}</span>
  }

  const renderTextItalic = text => {
    if (!text) return

    return <span className="List italic">{text}</span>
  }

  const renderListChildren = children => {
    if (!children || !children?.length) return

    return <List items={children} type="ol" />
  }

  const renderItems = () => {
    return (
      items &&
      items.map((item, index) => (
        <li className="List-item" key={`list-${index}`}>
          {renderItemTitleList(item.title)}
          <span dangerouslySetInnerHTML={{ __html: item.content}}></span>
          {renderTextItalic(item.italic)}
          {renderListChildren(item.children)}
        </li>
      ))
    )
  }

  return (
    <Fragment>
      {type === "ul" ? (
        <ul className={`List ${className}`}>{renderItems()}</ul>
      ) : (
        <ol className={`List ${className}`}>{renderItems()}</ol>
      )}
    </Fragment>
  )
}

List.propTypes = {
  items: PropTypes.array.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
}

List.defaultProps = {
  items: [],
  type: "ul",
  className: "",
}

export default List
