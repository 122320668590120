import React from "react"
import TitleBlock from "../components/TitleBlock"
import List from "../components/List"
import { inscriptionSteps } from "../data/inscription-efpa"
import Button from "../components/Button/ButtonLink"
import Layout from "../components/Layout"
import Breadcrumb from "../components/Breadcrumb"
import Hero from "../components/Hero"

export default function InscriptionEfpa() {
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Certificaciones", to: "/certificacion" },
    { label: "¿Cómo inscribirme a los exámenes de EFPA España?" },
  ]
  return (
    <Layout>
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <Breadcrumb path={path} />
        <TitleBlock
          title="¿Cómo inscribirme a los exámenes de EFPA España?"
          icon="far fa-file-alt"
        />
        <TitleBlock
          className="Certification-title-block"
          title="Inscripción individual"
          icon="fas fa-user"
        />
        <span className="Certification-text">
          Para acceder a la inscripción individual de los exámenes de EFPA
          España debe realizarla accediendo al enlace http://efpa.es/examenes
          siguiendo estos pasos:
        </span>
        <div className="Certification-container efpa-content">
          <List
            className="InscriptionEfpa-list"
            items={inscriptionSteps}
            type="ol"
          />
          <Button
            className="mb-3"
            label="Ver fechas exámenes"
            path="/fechas-examenes"
          />
          <span className="Certification-text font-weight-bold">
            Una vez obtenido el APTO después de un examen, es obligatorio darse
            de alta en la asociación para habilitar la certificación EFPA.
          </span>
          <span className="Certification-text font-weight-bold">
            Para más información contactar con examenes@efpa.es.
          </span>
        </div>

        <TitleBlock
          className="Certification-title-block"
          title="Inscripción para entidades"
          icon="fas fa-home"
        />
        <span className="Certification-text font-weight-bold">
          Para acceder a la inscripción de candidatos por parte de entidades o
          centros de estudio (abonen o no los derechos de examen) debe
          realizarla accediendo al enlace http://examenes.efpa.es/entidades
        </span>
        <Button
          className="Certification-btn mb-3"
          icon="far fa-file-pdf"
          label="Ver manual de inscripción de grupos"
        />
        <Button
          path="https://examenes.efpa.es/entidades/"
          blank
          className="mb-3"
          label="Inscripción grupos"
        />
        <span className="Certification-text font-weight-bold">
          Para más información contactar con examenes@efpa.es.
        </span>
      </div>
    </Layout>
  )
}
