export const inscriptionSteps = [
  {
    content: "Rellenar los datos que aparecen en la parte de nuevo registro.",
  },
  {
    content:
      "Recibirá un email con el usuario/contraseña y un enlace para acceder a la inscripción.",
  },
  {
    content: "Acceder al enlace con el usuario/contraseña asignado.",
  },
  {
    content: "Inscribirse a un examen y rellenar los campos solicitados.",
  },
  {
    content:
      "Seleccionar el examen que desea realizar y el lugar donde le gustaría realizarlo (El lugar definitivo dependerá del número de inscritos. EFPA España contactará con usted una vez se dispongan de los lugares definitivos, para que pueda reubicarse en el caso que no coincida).",
  },
  {
    content: "Adjuntar CV (formato PDF).",
  },
  {
    content: "Cumplimentar los datos de facturación.",
  },
  {
    content: "Realizar la transferencia mediante la pasarela TPV.",
  },
  {
    content:
      "Para finalizar el proceso de la transferencia, clicar en `Aceptar`.",
  },
  {
    content: `Diez días antes aproximadamente, recibirá un email con la convocatoria, con todos los datos de la inscripción.`,
  },
]
