import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const ButtonLink = props => {
  const { className, icon, label, path, blank, isFile, noIcon } = props
  const linkProps = blank
    ? {
        target: "_blank",
        rel: "noopener noreferrer",
      }
    : {}
  return (
    <Link className={`ButtonLink ${isFile? "isFile" : ""} ${className}`} to={path} {...linkProps}>
      {!noIcon && <i className={icon}></i>}
      {label}
    </Link>
  )
}

ButtonLink.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  path: PropTypes.string,
  label: PropTypes.string.isRequired,
  blank: PropTypes.bool,
  isFile: PropTypes.bool,
  noIcon: PropTypes.bool,
}

ButtonLink.defaultProps = {
  className: "",
  icon: "far fa-check-square",
  path: "/",
  label: "default label button",
}

export default ButtonLink
